import { Box, StepIconProps, Typography } from '@mui/material';
import { AppTheme, latoFontName } from 'common/themes/default';
import useTheme from '@mui/system/useTheme';
import { ReactComponent as ActiveStepLabelIcon } from 'img/active-step-label-icon.svg';
import { ReactComponent as DefaultStepLabelIcon } from 'img/default-step-label-icon.svg';
import { ReactComponent as CompletedStepLabelIcon } from 'img/completed-step-label-icon.svg';

export interface HexagonStepLabelIconProps extends StepIconProps {
  stepIndex: number;
}

const HexagonStepLabelIcon = (props: HexagonStepLabelIconProps) => {
  const { stepIndex, active, completed } = props;
  const theme = useTheme<AppTheme>();

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {!completed && (
          <Typography
            sx={{
              fontFamily: latoFontName,
              fontWeight: 'bold',
              position: 'absolute',
              zIndex: 100,
              left: '38%',
              top: '28%',
              color: active
                ? theme.palette.primary.contrastText
                : completed
                ? theme.palette.secondary.contrastText
                : 'white',
              fontSize: 10,
            }}
            variant="body2"
          >
            {stepIndex}
          </Typography>
        )}
        {active && (
          <ActiveStepLabelIcon style={{ width: '25px', height: '25px', marginTop: '5px' }} />
        )}
        {completed && (
          <CompletedStepLabelIcon style={{ width: '25px', height: '25px', marginTop: '5px' }} />
        )}
        {!active && !completed && (
          <DefaultStepLabelIcon style={{ width: '25px', height: '25px', marginTop: '5px' }} />
        )}
      </Box>
    </>
  );
};

export default HexagonStepLabelIcon;
