import styled from '@emotion/styled';
import { sizing, spacing, display } from '@mui/system';

const LoadingAnimatedLine = styled.div<any>`
  height: 16px;
  margin-bottom: 8px;
  animation: progress 1.2s ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 3px;
  ${sizing};
  ${spacing};
  ${display};

  ${props =>
    props.image &&
    `
    width: 60px;
    height: 60px;
    border-radius: 16px;
    margin: 16px;
  `}
`;

export default LoadingAnimatedLine;
