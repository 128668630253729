import React from 'react';
import LoadingSkeleton from './LoadingSkeleton';

const LoadingItems = ({ hasImage }) => (
  <>
    <LoadingSkeleton hasImage={hasImage} />
    <LoadingSkeleton hasImage={hasImage} />
    <LoadingSkeleton hasImage={hasImage} />
  </>
);

export default LoadingItems;
