// CONSTANTS
export const SHOW_SNACKBAR = 'treum/global/SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'treum/global/HIDE_SNACKBAR';

export const SHOW_GLOBAL_DIALOG = 'treum/global/SHOW_GLOBAL_DIALOG';
export const HIDE_GLOBAL_DIALOG = 'treum/global/HIDE_GLOBAL_DIALOG';
export const GLOBAL_DIALOG_TYPES = {
  newProduct: 'newProduct',
  newSupplier: 'newSupplier',
  requestCertificate: 'requestCertificate',
};

// REDUCER
const initialState = {
  snackbar: {
    visible: false,
    options: null,
  },
  contracts: {},
  globalDialog: {
    visible: false,
    type: null,
    meta: {},
  },
};

export function globalReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR: {
      const { options } = action.payload;
      return {
        ...state,
        snackbar: {
          visible: true,
          options,
        },
      };
    }
    case HIDE_SNACKBAR: {
      return {
        ...state,
        snackbar: {
          visible: false,
        },
      };
    }
    case SHOW_GLOBAL_DIALOG: {
      const { payload } = action;
      return {
        ...state,
        globalDialog: {
          visible: true,
          type: payload,
        },
      };
    }
    case HIDE_GLOBAL_DIALOG: {
      return {
        ...state,
        globalDialog: initialState.globalDialog,
      };
    }
    default:
      return state;
  }
}

// ACTIONS

interface SnackbarOptions {
  severity: "success" | "info" | "warning" | "error";
  message: string;
  timeout?: number;
}

/**
 * Displays a snackbar, visible from anywhere in the app
 * @param {object} options
 * @param {string} options.severity success|info|warning|error
 * @param {string} options.message
 */
export const showSnackbar = (options: SnackbarOptions) => ({
  type: SHOW_SNACKBAR,
  payload: {
    options,
  },
});

/**
 * Hides the global snackbar, opened with showSnackbar
 */
export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});

/**
 * Closes the new product dialog
 */
export function hideGlobalDialog() {
  return dispatch => {
    dispatch({
      type: HIDE_GLOBAL_DIALOG,
    });
  };
}

// SELECTORS
/**
 * Returns whether or not the global snackbar is visible
 * @param {object} state
 */
export const isSnackbarVisible = state => state.global.snackbar.visible;

/**
 * Returns current snackbar options
 * @param {object} state
 */
export const getSnackbarOptions = state => state.global.snackbar.options || {};

/**
 * Returns whether or not the global dialog is visible
 * @param {object} state
 */
export const isGlobalDialogVisible = state => state.global.globalDialog.visible;

/**
 * Returns the global dialog type
 * @param {object} state
 */
export const getGlobalDialogType = state => state.global.globalDialog.type;
