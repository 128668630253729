import React from 'react';
import styled from '@emotion/styled';
import { Icon, TextField } from './index';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  position: relative;
  line-height: 1;

  & > span[name='search'] {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;
  }
`;

const SearchField = ({ value, onChange, placeholder }) => (
  <Wrapper>
    <Icon name="search" size="24" color="primary.lighter" />
    <TextField
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      icon="search"
      small="true"
    />
  </Wrapper>
);

export default SearchField;
