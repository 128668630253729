import Tab from '@mui/material/Tab';
import { withStyles } from '@mui/styles';

export default withStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      marginBottom: '0 !important',
    },
  },
})(Tab);
