import styled from '@emotion/styled';
import { Paper } from '@mui/material';

const ListItem = styled(Paper)`
  width: 100%;
  margin-bottom: 16px;
  border-radius: 0;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 16px;
`;

export default ListItem;
