import React from 'react';
import { string, arrayOf, object } from 'prop-types';
import ReactHelmet from 'react-helmet';

function Helmet({ lang, meta, title }) {
  return (
    <ReactHelmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="Phosphor | %s"
      meta={[
        {
          property: `og:title`,
          content: title,
        },
      ].concat(meta)}
    />
  );
}

Helmet.defaultProps = {
  lang: `en`,
  meta: [],
};

Helmet.propTypes = {
  lang: string,
  meta: arrayOf(object),
  title: string.isRequired,
};

export default Helmet;
