import styled from '@emotion/styled';
import MUITextField from '@mui/material/TextField';
import { getStatusColor } from 'common/themes/utils';

const inputStyles = props => `
  border: 0.5px solid ${getStatusColor(props, props.theme.palette.primary.light)};
  padding: 16px;
  height: 56px;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
  color: ${props.theme.palette.primary.lighter};

  ::placeholder {
    font-style: italic;
    font-size: 0.9em;
  }

  ${props.small &&
    `
  height: 40px;
  padding: 12px;
`}
  
  &:hover {
    border: 1px solid ${getStatusColor(props, props.theme.palette.primary.darker)};
  }

  &:active, &:focus {
    border: 1px solid ${props.theme.palette.primary.darker};
  }

  &:disabled {
    border: 1px solid ${props.theme.palette.grey[100]};
    background-color: ${props.theme.palette.grey[100]};
  }
`;

const TextField = styled(({ icon, small, ...props }) => <MUITextField {...props} />)`
  && {
    ${props => (props.disabled ? 'pointer-events: none;' : '')}

    & > div {
      margin: 0;
      padding: 0;

      &:before,
      &:after {
        border-bottom: none !important;
      }

      & > input {
        ${props => inputStyles(props)};
        ${props => props.icon && 'padding-left: 48px;'}
        ${props => props.icon && props.small && 'padding-left: 40px;'}
      }

      & > textarea {
        ${props => inputStyles(props)};
      }
    }

    p {
      font-size: ${props => props.theme.typography.fontSizes.small}px;
    }
  }
`;

export default TextField;
