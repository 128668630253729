import * as types from './constants';

const initialState = {
  status: null,
  object: null,
  error: null,
  isAuthenticated: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.WEB3_REGISTER_REQUEST: {
      return {
        ...state,
        status: 'loading',
      };
    }
    case types.WEB3_REGISTER_SUCCESS: {
      return {
        ...state,
        status: 'success',
      };
    }
    case types.WEB3_REGISTER_ERROR: {
      const { payload } = action;
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    }
    case types.WEB3_VERIFY_REQUEST: {
      return {
        ...state,
        status: 'loading',
      };
    }
    case types.WEB3_VERIFY_SUCCESS: {
      return {
        ...state,
        status: 'success',
      };
    }
    case types.WEB3_VERIFY_ERROR: {
      const { payload } = action;
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    }
    case types.VERIFY_REQUEST: {
      return {
        ...state,
        status: 'loading',
      };
    }
    case types.VERIFY_SUCCESS: {
      return {
        ...state,
        status: 'success',
      };
    }
    case types.VERIFY_ERROR: {
      const { payload } = action;
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    }
    case types.AUTH_REQUEST: {
      return {
        ...state,
        status: 'loading',
      };
    }
    case types.AUTH_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        status: 'success',
        object: {
          ...payload,
        },
        error: null,
        isAuthenticated: true,
      };
    }
    case types.AUTH_ERROR: {
      const { payload } = action;
      return {
        ...state,
        status: 'error',
        error: payload,
        isAuthenticated: false,
      };
    }
    case types.LOAD_AUTH_REQUEST: {
      return {
        ...state,
        status: 'loading',
        isAuthenticated: false,
      };
    }
    case types.LOAD_AUTH_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        status: 'success',
        object: {
          ...payload,
        },
        error: null,
        isAuthenticated: true,
      };
    }
    case types.LOAD_AUTH_ERROR: {
      const { payload } = action;
      return {
        ...state,
        status: 'error',
        error: payload,
        isAuthenticated: false,
      };
    }
    case types.REMOVE_AUTH: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
