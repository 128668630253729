import * as React from 'react';
import { bool, string, object, arrayOf, func } from 'prop-types';
import styled from '@emotion/styled';
import { Icon } from './index';
import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { FIRST_PAGE, UsePaginatedQueryResult } from 'common/hooks/usePaginatedQuery';

interface BaseProps {
  width?: string | number;
  theme?: any;
  disabled?: boolean;
  active?: boolean;
  isGrid?: boolean;
}

const Titles = styled.div<BaseProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
`;

const Title = styled.div<BaseProps>`
  font-weight: bold;
  width: ${props => props.width};
  color: ${props => props.theme.palette.grey[400]};
  font-size: 12px;
  padding-left: 16px;
`;

const Content = styled.div<BaseProps>`
  display: flex;
  flex-wrap: wrap;
  ${props => (props.isGrid ? 'flex-direction: row;' : 'flex-direction: column;')}
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  overflow-x: auto;
`;

const Results = styled.div<BaseProps>`
  color: ${props => props.theme.palette.grey[400]};
`;

const PageNumbers = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PageLink = styled.div<BaseProps>`
  padding: 6px 12px;
  background-color: ${(props: any) => (props.active ? props.theme.palette.primary.main : 'white')};
  color: ${(props: any) => (props.active ? 'white' : props.theme.palette.grey[400])};
  ${(props: any) => !props.active && 'cursor: pointer;'}
  border-radius: 3px;
  margin-left: 8px;
`;

const PageControl = styled.div<BaseProps>`
  ${(props: any) => (props.disabled ? 'opacity: 0.4' : 'cursor: pointer')};

  span {
    color: ${props => props.theme.palette.grey[300]};
    margin-left: 8px;
  }
`;

interface PaginatedTableProps {
  titles?: string[];
  items?: any[];
  renderItems?(items: any[]): any;
  renderRow?(item: any, index: any): any;
  widths?: string[];
  pageSize?: number;
  isGrid?: boolean;
}

const PaginatedTable = ({
  titles,
  items = [],
  renderItems,
  renderRow,
  widths = [],
  pageSize = 5,
  isGrid,
}: PaginatedTableProps) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentItems, setCurrentItems] = React.useState([]);

  React.useEffect(() => {
    const maxItem = pageSize * (currentPage + 1);
    const cItems = items.filter(
      (item, i) => i >= pageSize * currentPage && i < Math.min(maxItem, items.length)
    );
    setCurrentItems(cItems as any);
  }, [items, currentPage, pageSize]);

  const pages = Array.from(
    {
      length: Math.ceil(items.length / pageSize),
    },
    (_, index) => index
  );

  const maxItem = pageSize * (currentPage + 1);

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage => currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage => currentPage + 1);
    }
  };

  return (
    <>
      <div>
        {titles && titles.length && (
          <Titles>
            {titles.map((title, i) => (
              <Title key={title} width={widths[i] || `${100 / titles.length}%`}>
                {title}
              </Title>
            ))}
          </Titles>
        )}
        {renderItems && currentItems
          ? renderItems(currentItems)
          : renderRow && (
              <Content isGrid={isGrid}>{currentItems.map((item, i) => renderRow(item, i))}</Content>
            )}
      </div>
      {items.length > pageSize && (
        <Pagination>
          <Results>
            Results: {pageSize * currentPage + 1} - {Math.min(maxItem, items.length)}
            {` of ${items.length}`}
          </Results>

          <PageNumbers>
            <PageControl disabled={currentPage === 0} onClick={prevPage}>
              <Icon size="16" name="chevronLeft" />
            </PageControl>
            {pages.map(page => (
              <PageLink
                key={page}
                active={currentPage === page}
                onClick={() => setCurrentPage(page)}
              >
                {page + 1}
              </PageLink>
            ))}
            <PageControl disabled={currentPage === pages.length - 1} onClick={nextPage}>
              <Icon size="16" name="chevronRight" />
            </PageControl>
          </PageNumbers>
        </Pagination>
      )}
    </>
  );
};

PaginatedTable.propTypes = {
  titles: arrayOf(string),
  widths: arrayOf(string),
  items: arrayOf(object),
  renderItems: func,
  renderRow: func,
  isGrid: bool,
};

export interface HeadCell {
  id: string;
  label: string;
  width?: string;
}

export interface BodyCell {
  id: string;
  content: any;
  width?: string;
}

interface V3PaginatedTableProps<T>
  extends Omit<UsePaginatedQueryResult<T>, 'status' | 'invalidate' | 'reset' | 'cursor'> {
  headCells: HeadCell[];
  createData: (data: T) => BodyCell[];
}

export function V3PaginatedTable<T>({
  page,
  rowsPerPage,
  rows,
  hasMore,
  totalRows,
  nextPage,
  goBackPage,
  changePage,
  changeRowsPerPage,
  isLoading,
  isError,
  error,
  headCells,
  createData,
}: V3PaginatedTableProps<T>) {
  if (isLoading) {
    return <CircularProgress />;
  } else if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  } else if (totalRows === 0) {
    return <Alert severity="info">No data to display</Alert>;
  } else {
    return (
      <TableContainer
        component={Paper}
        elevation={0}
        square
        sx={{ p: 0, mt: 2, height: 'calc(100% - 150px)' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map(cell => (
                <TableCell key={cell.id} width={cell.width} sx={{ px: 5 }}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsPerPage > 0 &&
              rows.map((row, i) => (
                <TableRow key={i}>
                  {createData(row).map(cell => (
                    <TableCell key={cell.id} width={cell.width} sx={{ px: 5 }}>
                      {cell.content}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter
            sx={{
              position: 'sticky',
              bottom: 0,
              background: 'white',
            }}
          >
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 100]}
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
                labelDisplayedRows={({ from, to }) =>
                  `${from}-${to === -1 ? totalRows : Math.min(to, totalRows)} of ${totalRows}`
                }
                nextIconButtonProps={{
                  'aria-label': 'next page',
                  onClick: nextPage,
                  disabled: !hasMore,
                }}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                  onClick: goBackPage,
                  disabled: page === FIRST_PAGE,
                }}
                sx={{ border: 'none' }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}

interface V3PaginatedGridProps<T>
  extends Omit<UsePaginatedQueryResult<T>, 'status' | 'invalidate' | 'reset' | 'cursor'> {
  createData: (data: T) => any;
}

export function V3PaginatedGrid<T>({
  page,
  rowsPerPage,
  rows,
  hasMore,
  totalRows,
  nextPage,
  goBackPage,
  changePage,
  changeRowsPerPage,
  isLoading,
  isError,
  error,
  createData,
}: V3PaginatedGridProps<T>) {
  if (isLoading) {
    return <CircularProgress />;
  } else if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  } else if (totalRows === 0) {
    return <Alert severity="info">No data to display</Alert>;
  } else {
    return (
      <TableContainer
        component={Paper}
        elevation={0}
        square
        sx={{ p: 0, mt: 2, height: 'calc(100% - 150px)' }}
      >
        <Box display="flex">
          {rowsPerPage > 0 && rows.map((row, i) => <Box key={i}>{createData(row)}</Box>)}
        </Box>
        <Table>
          <TableFooter
            sx={{
              position: 'sticky',
              bottom: 0,
              background: 'white',
            }}
          >
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 100]}
                count={-1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
                labelDisplayedRows={({ from, to }) =>
                  `${from}-${to === -1 ? totalRows : Math.min(to, totalRows)} of ${totalRows}`
                }
                nextIconButtonProps={{
                  'aria-label': 'next page',
                  onClick: nextPage,
                  disabled: !hasMore,
                }}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                  onClick: goBackPage,
                  disabled: page === FIRST_PAGE,
                }}
                sx={{ border: 'none' }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}

export default PaginatedTable;
