export const WEB3_REGISTER_REQUEST = 'treum/auth/WEB3_REGISTER_REQUEST';
export const WEB3_REGISTER_SUCCESS = 'treum/auth/WEB3_REGISTER_SUCCESS';
export const WEB3_REGISTER_ERROR = 'treum/auth/WEB3_REGISTER_ERROR';

export const WEB3_VERIFY_REQUEST = 'treum/auth/WEB3_VERIFY_REQUEST';
export const WEB3_VERIFY_SUCCESS = 'treum/auth/WEB3_VERIFY_SUCCESS';
export const WEB3_VERIFY_ERROR = 'treum/auth/WEB3_VERIFY_ERROR';

export const VERIFY_REQUEST = 'treum/auth/VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'treum/auth/VERIFY_SUCCESS';
export const VERIFY_ERROR = 'treum/auth/VERIFY_ERROR';

export const AUTH_REQUEST = 'treum/auth/AUTH_REQUEST';
export const AUTH_SUCCESS = 'treum/auth/AUTH_SUCCESS';
export const AUTH_ERROR = 'treum/auth/AUTH_ERROR';

export const LOAD_AUTH_REQUEST = 'treum/auth/LOAD_AUTH_REQUEST';
export const LOAD_AUTH_SUCCESS = 'treum/auth/LOAD_AUTH_SUCCESS';
export const LOAD_AUTH_ERROR = 'treum/auth/LOAD_AUTH_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'treum/auth/FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'treum/auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'treum/auth/FORGOT_PASSWORD_ERROR';

export const UPDATE_PASSWORD_REQUEST = 'treum/auth/UPDATE_PASSWORD_REQUEST';

export const REMOVE_AUTH = 'treum/auth/REMOVE_AUTH';
