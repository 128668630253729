import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from 'atoms';
import { palette } from '@mui/system';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { latoFontName } from 'common/themes/default';

interface PalleteSystemProps {
  color?: string;
  bgcolor?: string;
}

export type EthAddressProps = PalleteSystemProps & {
  address: string;
  networkId: number;
  full?: boolean;
  charLengthOnEitherSide?: number;
  showIcon?: boolean;
  iconSize?: number;
  iconColor?: string;
  showBlockExplorer?: boolean;
};

export const AddressDisplay = styled(Box)<EthAddressProps>`
  color: ${props => (props.color ? props.color : props.theme.palette.primary.main)};
  cursor: pointer;
  font-family: '${latoFontName}';
  display: flex;
  align-items: center;
  &:hover{
    font-weight: bold;
  }
  ${palette};
`;

export const AddressValue = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 1;
`;

const EthAddressExplorer = ({
  address = '',
  networkId = 1,
  showIcon = false,
  iconSize = 22,
  iconColor = 'black',
  full = false,
  charLengthOnEitherSide = 4,
  showBlockExplorer = true,
  ...rest
}) => {
  const displayedAddress = useMemo(() => {
    if (full || !address) return address;
    return `${address.slice(0, 2 + charLengthOnEitherSide)}...${address.slice(
      0 - charLengthOnEitherSide
    )}`;
  }, [address, full, charLengthOnEitherSide]);

  const [copied, setCopied] = useState(false);

  const supportedNetworks = [1, 4, 5, 100, 137, 80001];

  const openExplorer = () => {
    let url = '';
    switch (networkId) {
      default:
        url = `https://etherscan.io/address/${address}`;
        break;
      case 4:
        url = `https://rinkeby.etherscan.io/address/${address}`;
        break;
      case 5:
        url = `https://goerli.etherscan.io/address/${address}`;
        break;
      case 100:
        url = `https://blockscout.com/xdai/mainnet/address/${address}`;
        break;
      case 137:
        url = `https://polygonscan.com/address/${address}`;
        break;
      case 80001:
        url = `https://mumbai.polygonscan.com/address/${address}`;
        break;
    }
    if (url) {
      window.open(url);
    }
  };

  return (
    <AddressDisplay address={address} networkId={networkId}>
      <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
        <Box display="flex" alignItems="center">
          <CopyToClipboard text={address} onCopy={() => setCopied(true)}>
            <AddressDisplay
              address={address}
              networkId={networkId}
              {...rest}
              onMouseLeave={() => setCopied(false)}
            >
              <AddressValue>{displayedAddress}</AddressValue>
              {showIcon && (
                <Icon
                  name="copy"
                  size={`${iconSize}`}
                  display="inline-block"
                  height={`${iconSize}px`}
                  ml="8px"
                  color={iconColor}
                />
              )}
            </AddressDisplay>
          </CopyToClipboard>
        </Box>
      </Tooltip>

      {showBlockExplorer && supportedNetworks.includes(networkId) && (
        <Tooltip title={'View in Explorer'}>
          <ExitToAppOutlinedIcon fontSize="small" onClick={openExplorer} />
        </Tooltip>
      )}
    </AddressDisplay>
  );
};

export default EthAddressExplorer;
