import styled from '@emotion/styled';
import { Switch as MUISwitch } from '@mui/material';

export default styled(MUISwitch)`
  && .MuiSwitch-thumb {
    background-color: white;
  }
  && .MuiSwitch-track {
    height: 9px;
    margin-top: 3px;
    background-color: ${props => props.theme.palette.grey[300]};
    opacity: 1 !important;
  }
  && .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.palette.primary.main};
  }

  ${props => props.disabled && 'opacity: 0.7;'}
`;
