import { combineReducers } from 'redux';
import { globalReducer } from 'common/store/global';
import auth from 'features/auth/store/reducer';

const rootReducer = combineReducers({
  auth,
  global: globalReducer,
});

export default rootReducer;
