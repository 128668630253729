/* eslint-disable camelcase */
import { BigNumberish, formatUnits } from 'ethers';

export const isCurrentURL = url => window.location.pathname === url;

export const formatAuthHeader = (authType: string, accessToken: string) => {
  return `${authType} ${accessToken}`;
};

export const formatError = error => {
  console.error(error);

  if (typeof error === 'string') {
    return error;
  }

  if (error?.response?.data) {
    const { data } = error.response;

    if (data.message) {
      return data.message;
    }

    if (data.errors?.length) {
      return data.errors[0].detail;
    }

    if (data.error?.detail) {
      return data.error.detail;
    }
  }

  if (error?.message) {
    return error.message;
  }

  return 'Something went wrong. Please try again.';
};

export const removeTrailingSlash = url => {
  return url.replace(/\/$/, '');
};

const quotaError = errorObject => errorObject?.request?.status === 402;

export const apiErrorMessageResolver = (primaryMessage, errorObject) => {
  if (quotaError(errorObject)) {
    return `You have reached your plan's limit. Please contact support.`;
  }
  const reasons: string[] = [];
  try {
    errorObject.response.data.errors.forEach(({ code, detail }) => {
      if (code === 'IntegrityError') {
        if (detail.indexOf('ingredient_supplier_id') !== -1) {
          reasons.push('the ingredient is being used in a supplier request');
        } else if (detail.indexOf('certificate_request_claim_id_fkey') !== -1) {
          reasons.push('the claim is being used in a certificate request');
        } else if (detail.includes('ingredient_parent_product_id_name_key')) {
          reasons.push('the ingredient already exists');
        }
      }
    });
  } catch (ex) {
    console.error(ex);
  }
  const reason = reasons.length ? ` because ${reasons.join(' and ')}` : '';
  if (primaryMessage) {
    return primaryMessage;
  }
  if (reason) {
    return `Something went wrong${reason}. Please try again.`;
  }
  return formatError(errorObject);
};

const FORMAT_CURRENCY_SYMBOLS = {
  USD: '$',
  ETH: 'Ξ',
};

const FORMAT_CURRENCY_DECIMALS = {
  USD: 2,
  ETH: 5,
};

export const formatCurrency = (value, currency, { symbol = true, lang = 'en-US' } = {}) => {
  const formattedSymbol = symbol ? FORMAT_CURRENCY_SYMBOLS[currency] : '';
  const formattedValue = new Intl.NumberFormat(lang, {
    minimumFractionDigits: FORMAT_CURRENCY_DECIMALS[currency],
  }).format(value);
  return formattedSymbol + formattedValue;
};

export const fromBase = (value: BigNumberish, units: number | string = 18): string => {
  return formatUnits(value, units);
};

export const parseAsArray = (input: string) => {
  let result = [];

  try {
    const parsed = JSON.parse(input);
    if (parsed && Array.isArray(parsed)) {
      result = parsed as any;
    }
  } catch (e) {
    console.error('Failed to parse input as array');
  }

  return result;
};

export const mergeState = (source: any, target: any) => {
  if (!source || !target) {
    return target;
  }

  let shouldMerge = false;
  Object.keys(source).forEach(key => {
    if (shouldMerge) {
      return;
    }
    shouldMerge = source[key] !== target[key];
  });

  return shouldMerge ? { ...source, ...target } : source;
};
