module.exports = {
  baseApiUrl: window.env.REACT_APP_ADMIN_WEB_API_URL || 'http://localhost:5000',
  baseApiV3Url: window.env.REACT_APP_ADMIN_WEB_API_V3_URL || 'http://localhost:5000/v3',
  publicApiV3Url:
    window.env.REACT_APP_ADMIN_WEB_PUBLIC_API_V3_URL || 'http://localhost:5000/public/v1',
  deploymentTag: window.env.REACT_APP_DEPLOYMENT_TAG,

  featureFlagsClientId: window.env.REACT_APP_FEATURE_FLAGS_CLIENT_ID || '',

  imageAllowedExtensions: ['jpg', 'jpeg', 'png', 'svg', 'gif'],
  imageMaxFilesizeMB: 100,

  dappName: window.env.REACT_APP_ADMIN_WEB_DAPP_NAME || 'Phosphor',
  sidebarWidth: 350,
  lazyLoadFeedbackMinimumDelay: 550,
  mobileNavBreakpoint: 680,
  mobileHeaderHeight: 56,

  defaultOrganizationId: window.env.REACT_APP_ADMIN_WEB_DEFAULT_ORGANIZATION_ID,

  chainId: parseInt(window.env.REACT_APP_ADMIN_WEB_CHAIN_ID, 10) || 4,

  authDomain: window.env.REACT_APP_AUTH0_DOMAIN,
  authClientId: window.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: window.env.REACT_APP_AUTH0_API_AUDIENCE,

  environmentName: window.env.ENVIRONMENT_NAME || 'local',
};
