import { Provider } from 'react-redux';
import PlatformThemeProvider from 'common/themes/PlatformThemeProvider';
import store from 'store/index';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
    },
  },
});

/**
 * Setup all the global providers here
 */
const AppProviders = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PlatformThemeProvider>{children}</PlatformThemeProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen />
  </QueryClientProvider>
);

export default AppProviders;
