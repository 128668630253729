import styled from '@emotion/styled';
import Tabs from '@mui/material/Tabs';
import { spacing, sizing } from '@mui/system';

export default styled(Tabs)<any>`
  margin: 24px;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${props => props.theme.palette.grey[200]};
    position: absolute;
    bottom: 0;
  }

  & > div > span {
    background-color: ${props => props.theme.palette.primary.main};
    color: red;
    height: 3px;
    border-radius: 2px;
    z-index: 2;
  }

  button {
    text-transform: none;
  }

  ${spacing};
  ${sizing};
`;
