import styled from '@emotion/styled';
import { spacing } from '@mui/system';

export default styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-left: 8px;
    min-width: 100px;
  }

  ${spacing}
`;
