import { Box, Paper } from '@mui/material';
import Helmet from './Helmet';

interface PageProps extends React.PropsWithChildren<any> {
  background?: string;
  helmet?: string;
  title?: string;
  testIdPrefix?: string;
  width?: string;
  height?: string;
  padding?: string;
  border?: string;
}

const Page = ({
  children,
  helmet,
  testIdPrefix,
  background,
  width,
  height,
  border,
  padding,
  allowVerticalScroll = false,
}: PageProps) => {
  return (
    <>
      <Paper
        elevation={0}
        square
        sx={{
          overflowY: allowVerticalScroll ? 'auto' : 'hidden',
          border: border,
          padding: padding ?? '25px 50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: width ?? '100%',
          height: height ?? '100%',
          background: background ?? 'transparent',
        }}
      >
        {helmet && <Helmet title={helmet} />}
        <Box
          sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}
          data-testid={`page-${testIdPrefix}`}
        >
          {children}
        </Box>
      </Paper>
    </>
  );
};

export default Page;
