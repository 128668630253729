import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { ReactComponent as LogoSvg } from 'img/organization-logo.svg';
import { ReactComponent as LogoTextSvg } from 'img/organization-logo-text.svg';

const LogoText = styled<any>(({ fillColor, ...rest }) => <LogoTextSvg {...rest} />)`
  > path {
    fill: ${props => props.fillColor ?? 'white'};
  }
`;

const Logo = styled<any>(({ fillColor, spinLogo, ...rest }) => <LogoSvg {...rest} />)`
  ${props =>
    props.spinLogo &&
    `animation: spin 8s linear infinite;

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }`}

  > path {
    fill: ${props => props.fillColor ?? 'white'};
  }
`;

export interface OrganizationLogoProps {
  spinLogo?: boolean;
  showLogo?: boolean;
  showText?: boolean;
  fillColor?: string;
  textColor?: string;
  logoSize?: string;
  textWidth?: string;
  stacked?: boolean;
}

const OrganizationLogo = ({
  spinLogo,
  showLogo = true,
  showText,
  fillColor,
  textColor,
  logoSize,
  textWidth,
  stacked,
  ...rest
}: OrganizationLogoProps) => {
  return (
    <Box display="flex" alignItems="center" {...rest} flexDirection={stacked ? 'column' : 'row'}>
      {showLogo && (
        <Logo
          spinLogo={spinLogo ?? false}
          fillColor={fillColor ?? 'white'}
          width={logoSize ?? '100%'}
          height={logoSize ?? '100%'}
        />
      )}

      {showText && (
        <Box
          sx={{
            ml: stacked ? '0' : '5px',
            mt: stacked ? '15px' : '0',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LogoText fillColor={fillColor ?? 'white'} width={textWidth ?? '165px'} height="100%" />
        </Box>
      )}
    </Box>
  );
};

export default OrganizationLogo;
