import * as React from 'react';
import styled from '@emotion/styled';
import {
  palette,
  spacing,
  borders,
  display,
  sizing,
  flexbox,
  PaletteProps,
  SpacingProps,
  BordersProps,
  DisplayProps,
  SizingProps,
  FlexboxProps,
} from '@mui/system';
import get from 'lodash/get';
import icons from './icons';

interface IconBaseProps {
  name: string;
  size: string;
  color?: string;
  bgColor?: string;
  circled?: boolean;
  testId?: string;
  onClick?(): () => any;
}

type IconProps = IconBaseProps &
  PaletteProps &
  SpacingProps &
  BordersProps &
  DisplayProps &
  FlexboxProps &
  SizingProps;

const Wrapper = styled.span<IconProps>`
  ${props =>
    props.circled &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${parseInt(props.size, 10) / 2}px;
    border-radius: 50%;
    background-color: ${props.bgColor ? props.bgColor : props.theme.palette.primary.main};
  `}

  ${palette};
  ${spacing};
  ${borders};
  ${display};
  ${sizing};
  ${flexbox};
`;

const SVGIcon = styled.i<IconProps & { icon: string }>`
  display: inline-block;
  width: ${props => props.width || props.size}px;
  height: ${props => props.height || props.size}px;
  background-color: ${props =>
    props.circled ? 'white' : get(props.theme.palette, props.color) || props.color};
  -webkit-mask: url(${props => props.icon}) no-repeat center;
  mask: url(${props => props.icon}) no-repeat center;
  -webkit-mask-size: 100% auto;
  mask-size: 100% auto;
  align-self: middle;
`;

const Icon = ({ name, color, bgColor, size, circled, testId, ...rest }: IconProps) =>
  icons[name] ? (
    <Wrapper
      data-testid={testId}
      name={name}
      bgColor={bgColor}
      size={size}
      circled={circled}
      {...rest}
    >
      <SVGIcon name={name} color={color} size={size} icon={icons[name]} {...rest} />
    </Wrapper>
  ) : (
    <span data-testid={testId}>{name}</span>
  );

Icon.defaultProps = {
  size: '18',
  color: 'black',
};

export default Icon;
