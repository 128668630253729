import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Button, ButtonProps } from './Button';

export type ButtonWithTooltipProps = ButtonProps & {
  tooltipText?: string;
  onlyWhenDisabled?: boolean;
};

const ButtonWithTooltip = ({
  tooltipText = '',
  onlyWhenDisabled = false,
  children,
  ...buttonProps
}: ButtonWithTooltipProps) => {
  const { disabled } = buttonProps;
  const showTooltip = onlyWhenDisabled ? disabled : Boolean(tooltipText);

  return showTooltip ? (
    <Tooltip title={tooltipText}>
      <div style={{ marginLeft: '8px' }}>
        <Button {...buttonProps}>{children}</Button>
      </div>
    </Tooltip>
  ) : (
    <Button {...buttonProps}>{children}</Button>
  );
};

export default ButtonWithTooltip;
