import styled from '@emotion/styled';
import MUIDialog from '@mui/material/Dialog';
import MUIDialogActions from '@mui/material/DialogActions';
import {
  sizing,
  palette,
  spacing,
  typography,
  borders,
  SizingProps,
  PaletteProps,
  SpacingProps,
  TypographyProps,
  BordersProps,
} from '@mui/system';

type ModalStyleProps = SizingProps & PaletteProps & SpacingProps & TypographyProps & BordersProps;

const Modal: any = styled(MUIDialog)<ModalStyleProps>`
  & > div > div {
    overflow-y: visible;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 40px;
    max-width: 496px;
    ${sizing};
    ${palette};
    ${spacing};
    ${typography};
    ${borders};
  }
`;

Modal.Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

Modal.Title = styled.h1<ModalStyleProps>`
  font-size: ${props => props.theme.typography.fontSizes.h1}px;
  color: ${props => props.theme.palette.grey[500]};
  margin: 0;
  ${sizing};
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
`;

Modal.Content = styled.div<ModalStyleProps>`
  margin: 1.5em 0;
  font-size: ${props => props.theme.typography.fontSize}px;
  color: ${props => props.theme.palette.grey[400]};
  line-height: 1.5;
  ${sizing};
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
`;

Modal.Actions = styled(MUIDialogActions)<ModalStyleProps>`
  && {
    justify-content: flex-end;
    padding: 0;

    & :not(:first-of-type) {
      margin-left: 16px;
    }
    ${sizing};
    ${palette};
    ${spacing};
    ${typography};
    ${borders};
  }
`;

Modal.CloseIcon = styled.div`
  cursor: pointer;
`;

Modal.Options = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    margin-left: 16px !important;
  }
`;

export default Modal;
