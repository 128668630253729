import React, { useState } from 'react';
import styled from '@emotion/styled';
import MUIMenuList from '@mui/material/MenuList';
import MUIMenuItem from '@mui/material/MenuItem';
import { Box, useTheme } from '@mui/system';
import { Icon } from './index';

interface MenuType {
  Main?: any;
  Item?: any;
  Section?: any;
  Collapsible?: any;
  SubItem?: any;
}

const Menu: MenuType = {};

Menu.Main = styled(MUIMenuList)`
  outline: 0;
`;

const MenuItem = styled(MUIMenuItem)<any>`
  && {
    padding: 0;
    height: 16px;
    display: flex;
    margin: 4px 28px;
    border-radius: 4px;
    cursor: pointer;
    line-height: 1;

    ${props =>
      props.disabled &&
      `
      opacity: 0.5;
      pointer-events: none;
    `};

    @media (max-width: 680px) {
      margin: 4px 16px;
    }
  }
`;

const MenuText = styled.span<any>`
  color: ${props => props.theme.palette.grey[800]};
  margin-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  flex-grow: 2
    ${props =>
      props.selected &&
      `
    color: ${props.theme.palette.primary.main};
`};
  ${props =>
    props.small &&
    `
    font-size: 1rem;
`};
  ${props =>
    props.indented &&
    `
    margin-left: 48px;
  `};
`;

Menu.Item = React.forwardRef(({ icon, theme, children, ...rest }: any, ref) => (
  <MenuItem ref={ref} {...rest}>
    {typeof icon === 'string' ? (
      <Icon name={icon} color={theme.palette.primary.dark} size="24" marginLeft="2px" />
    ) : (
      icon
    )}
    <MenuText {...rest}>{children}</MenuText>
  </MenuItem>
));

Menu.Collapsible = React.forwardRef(
  ({ icon, title, children, contentStyles, openDefault, ...rest }: any, ref) => {
    const [open, setOpen] = useState(openDefault);
    const toggle = () => {
      setOpen(open => !open);
    };
    const theme = useTheme();

    return (
      <>
        <MenuItem ref={ref} {...rest} onClick={toggle} sx={{ borderBottom: '1px solid black' }}>
          {typeof icon === 'string' ? (
            <Icon name={icon} color={theme.palette.grey[800]} size="24" />
          ) : (
            icon
          )}
          <MenuText>{title}</MenuText>
          <Icon
            name={open ? 'chevronUp' : 'chevronDown'}
            color={theme.palette.grey[800]}
            size="24"
          />
        </MenuItem>
        <Box
          sx={[
            {
              width: 1,
              height: 0,
              display: 'none',
              paddingBottom: 0,
              overflow: 'hidden',
              borderRadius: 2,
            },
            open && {
              height: 'auto',
              display: 'block',
              paddingBottom: 2,
              overflow: 'visible',
            },
          ]}
          open={open}
          {...contentStyles}
        >
          {children}
        </Box>
      </>
    );
  }
);

Menu.SubItem = React.forwardRef(({ children, ...rest }: any, ref) => (
  <MenuItem ref={ref} {...rest}>
    <MenuText small indented {...rest}>
      {children}
    </MenuText>
  </MenuItem>
));

const MenuSection = styled.div`
  margin-top: 5px;

  & > h4 {
    text-transform: uppercase;
    font-style: normal;
    font-size: 14px;
    color: ${props => props.theme.customPalette.black};
    padding: 0;
    margin: 10px 28px;
  }

  .MuiMenuItem-root {
    padding: 18px 28px !important;
  }
`;

Menu.Section = ({ title, children }) => (
  <MenuSection>
    {title && <h4>{title}</h4>}
    {children}
  </MenuSection>
);

export default Menu;
