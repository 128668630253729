import styled from '@emotion/styled';
import { sizing, palette, spacing, typography, borders } from '@mui/system';

const Flex = styled(
  ({
    wrapReverse,
    wrap,
    justifyContent,
    justifyAround,
    justifyBetween,
    justifyCenter,
    justifyEnd,
    alignItems,
    alignStretch,
    alignCenter,
    alignEnd,
    alignBaseline,
    column,
    ...props
  }) => <div {...props} />
)<any>`
  display: flex;

  flex-wrap: ${props => {
    if (props.wrapReverse) return 'wrap-reverse';
    else if (props.wrap) return 'wrap';
    return 'nowrap';
  }};
  justify-content: ${props => {
    if (props.justifyContent) return props.justifyContent;
    if (props.justifyCenter) return 'center';
    else if (props.justifyAround) return 'space-around';
    else if (props.justifyBetween) return 'space-between';
    else if (props.justifyEnd) return 'flex-end';
    return 'flex-start';
  }};
  align-items: ${props => {
    if (props.alignItems) return props.alignItems;
    else if (props.alignStretch) return 'stretch';
    else if (props.alignEnd) return 'flex-end';
    if (props.alignCenter) return 'center';
    else if (props.alignBaseline) return 'baseline';
    return 'flex-start';
  }};
  flex-direction: ${props => {
    if (props.column) {
      return 'column';
    }
    return 'row';
  }};

  ${sizing};
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
`;

Flex.displayName = 'Flex';

export default Flex;
