import styled from '@emotion/styled';
import MUIButton from '@mui/material/Button';
import { CircularProgress, useTheme } from '@mui/material';
import Icon from '../Icon';
import { Box } from '@mui/material';

export interface ButtonProps {
  children?: any;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  disabled?: boolean;
  loading?: boolean;
  loadingLabel?: string;
  icon?: string;
  size?: 'small' | 'medium' | 'large';
  theme?: any;
  type?: 'button' | 'submit';
  onClick?(event: any): any;
  fullWidth?: boolean;
  autofocus?: boolean;
}

function getIconStatusColors(props: ButtonProps) {
  // TODO: Do this with theme customization.. for some reason this didn't work.
  if (props.color === 'primary') {
    return `i { color: ${props.theme.palette.primary.lighter}; &:disabled { i { background-color: ${props.theme.palette.grey.main}; } }`;
  }

  if (props.color === 'secondary') {
    return `i { background-color: #212121; } &:disabled { i { background-color: #212121; } }`;
  }

  if (props.color === 'error') {
    return `i { background-color: ${props.theme.palette.error.main}; } &:disabled { i { background-color: ${props.theme.palette.error.main}; } }`;
  }

  return null;
}

// prettier-ignore
const Wrapper = styled<any>(MUIButton)`
  && {
    text-transform: none;
    border-radius: 4px;
    border-width: 2px;
    font-size: ${props => props.theme.typography.fontSize}px;
    height: 36px;
    padding: 12px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 124px;
    line-height: 1;
    ${props => props.loading && `background-color: ${props.theme.palette.primary.dark};`}
    ${props => props.size === 'small' && `height: 40px; padding: 8px 16px;`};
    ${props => props.size === 'large' && `font-size: 18px; height: 56px; padding: 15px 24px;`};
    ${props => props.fullWidth && `width: 100%;`};

    

    &:hover {
      border-width: 2px;
    }

    & > span {
      display: flex;
    }

    ${getIconStatusColors};
  }

  i {
    display: inline-block;
    margin-right: 8px;
  }
`;

const LoadingLabel = styled.div`
  color: ${props => props.color};
`;

const Button = ({
  icon,
  children,
  disabled,
  size,
  loading,
  loadingLabel,
  onClick,
  color,
  variant,
  ...rest
}: ButtonProps) => {
  const theme = useTheme();
  const iconColor = disabled
    ? theme.palette.grey[200]
    : variant === 'outlined'
    ? theme.palette.primary.main
    : color === 'primary'
    ? theme.palette.primary.contrastText
    : color === 'secondary'
    ? theme.palette.primary.contrastText
    : theme.palette.grey[500];
  const iconSize = size === 'small' ? '24' : size === 'large' ? '32' : '24';

  // disable clicks while loading
  const statefulOnClick = (event: any) => {
    if (loading) return;
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Wrapper
      theme={theme}
      size={size}
      disableElevation
      disabled={disabled}
      onClick={statefulOnClick}
      color={color}
      variant={variant}
      {...rest}
    >
      {loading ? (
        <>
          <CircularProgress
            size="25px"
            sx={{
              color: disabled || color === 'info' || variant === 'outlined' ? 'black' : 'white',
            }}
          />

          {loadingLabel && (
            <Box display="flex" height="100%" alignItems="center" marginLeft={1}>
              <LoadingLabel
                color={disabled || color === 'info' || variant === 'outlined' ? 'black' : 'white'}
              >
                {loadingLabel}
              </LoadingLabel>
            </Box>
          )}
        </>
      ) : (
        <>
          {icon && <Icon name={icon} size={iconSize} color={iconColor} />}
          <span>{children}</span>
        </>
      )}
    </Wrapper>
  );
};

export default Button;
