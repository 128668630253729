import React from 'react';
import { bool } from 'prop-types';
import styled from '@emotion/styled';
import { sizing, spacing } from '@mui/system';
import LoadingAnimatedLine from './LoadingAnimatedLine';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
  margin-bottom: 16px;
  ${sizing};
  ${spacing};

  @keyframes progress {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 100px);
  padding: 16px;
`;

const LoadingSkeleton = ({ hasImage, ...rest }) => (
  <Wrapper {...rest}>
    {hasImage && <LoadingAnimatedLine image />}

    <Content>
      <LoadingAnimatedLine width="120px" />
      <LoadingAnimatedLine width="100%" />
      <LoadingAnimatedLine width="80%" />
    </Content>
  </Wrapper>
);

LoadingSkeleton.propTypes = {
  hasImage: bool,
};

export default LoadingSkeleton;
