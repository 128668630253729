import * as React from 'react';
import styled from '@emotion/styled';
import { palette, spacing, typography, borders, display } from '@mui/system';

const Wrapper = styled.div<any>`
  display: ${props => props.display || 'inline-block'};
  border: ${props => (props.border ? `1px solid ${props.theme.palette.grey[200]}` : 'none')};
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
  ${display};
`;

const Text = props => <Wrapper {...props} />;

export default Text;
