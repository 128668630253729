export const LAYOUT_MODES = {
  list: 'LIST',
  grid: 'GRID',
};

export const NO_AUTH_IN_STORAGE = 'No auth in storage';

export const PLAN_METER_TARGET_OPTIONS = [
  {
    value: 'MINT_TOKEN',
    label: 'Token minting',
  },
  {
    value: 'TOKEN_CONTRACT',
    label: 'Token contracts',
  },
  {
    value: 'USER_COUNT',
    label: 'Users',
  },
  {
    value: 'PRODUCT_COUNT',
    label: 'Products',
  },
  {
    value: 'CLAIM_COUNT',
    label: 'Claims',
  },
  {
    value: 'CERTIFICATE_COUNT',
    label: 'Certificates',
  },
];

export const PLAN_METER_TYPE_OPTIONS = [
  {
    value: 'FIXED',
    label: 'Total',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
];

export const TARGET_DESCRIPTIONS = {
  MINT_TOKEN: {
    monthly: 'Unused tokens do not rollover to next month.',
    fixed: 'Total number of tokens in the platform.',
  },
  TOKEN_CONTRACT: {
    monthly: 'Unused contracts do not rollover to next month.',
    fixed: 'Total number of token contracts in the platform.',
  },
  USER_COUNT: {
    monthly: 'Unused users do not rollover to next month.',
    fixed: 'Total number of users in the platform.',
  },
  PRODUCT_COUNT: {
    monthly: 'Unused products do not rollover to next month.',
    fixed: 'Total number of products in the platform.',
  },
  CLAIM_COUNT: {
    monthly: 'Unused claims do not rollover to next month.',
    fixed: 'Total number of claims in the platform.',
  },
  CERTIFICATE_COUNT: {
    monthly: 'Unused certificaes do not rollover to next month.',
    fixed: 'Total number of certificates in the platform.',
  },
};

export const ITEM_SALE_STATUS_LABELS = {
  NOT_AVAILABLE: 'Not Listed',
  AVAILABLE: 'Set Price',
  SOLD: 'Sold',
  AUCTION: 'Auction',
  AUCTION_ENDED: 'Auction Ended',
};

export const COMMON_ATTRIBUTE_IDS = [
  // meta
  'item_type_id',
  'collection_id',
  'ipfs_hash',
  // core
  'title',
  'description',
  'image_url',
  // token
  'erc721_tokenid',
  'token_owner',
  'token_status',
];

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const FILE_STATUS = {
  loading: 'loading',
  error: 'error',
  success: 'success',
};

export const UPLOAD_LIMIT_MB = 100;

export const SIGNING_WALLET_NAME = 'DefaultSigner';
