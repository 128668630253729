import { createStore, compose, applyMiddleware } from 'redux';
import throttle from 'lodash/throttle';
import thunk from 'redux-thunk';
import { saveState, loadState } from 'common/utils/localStorage';
import rootReducer from './rootReducer';

export function configureStore(initialState) {
  const middlewares = [thunk];

  const enhancers = [applyMiddleware(...middlewares)];
  /* eslint-disable */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  return createStore(rootReducer, initialState, composeEnhancers(...enhancers));
}

const store = configureStore(loadState());

store.subscribe(
  throttle(() => {
    saveState({
      transactions: store.getState().transactions,
    });
  }, 1000)
);

export default store;
