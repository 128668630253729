import { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { Icon } from './index';
import { Options } from 'atoms';
import { palette, spacing, sizing } from '@mui/system';
import { Divider, Paper } from '@mui/material';

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    color: ${props => props.theme.palette.grey[400]};
    cursor: pointer;
    margin: 0;
  }

  & > div > div > span {
    display: inline-block;
    margin-left: 24px;
    margin-top: 12px;
    cursor: pointer;
  }

  ${palette};
  ${spacing};
  ${sizing};
`;

const Content = styled.div<any>`
  height: ${props => (props.open ? 'auto' : '0')};
  display: ${props => (props.open ? 'inline-block' : 'none')};
  padding-top: ${props => (props.open ? '10px' : '0')};
  overflow: ${props => (props.open ? 'visible' : 'hidden')};
  ${palette};
  ${spacing};
  ${sizing};
`;

interface CollapsibleBoxProps {
  id?: string;
  title: any;
  children: any;
  options?: any;
  startClosed?: boolean;
  boxStyles?: any;
  openHeaderStyles?: any;
  closedHeaderStyles?: any;
  contentStyles?: any;
}

const CollapsibleBox = ({
  id,
  title,
  options,
  children,
  startClosed = false,
  boxStyles = {},
  openHeaderStyles = {},
  closedHeaderStyles = {},
  contentStyles = {},
}: CollapsibleBoxProps) => {
  const [open, setOpen] = useState(!startClosed);
  const toggle = () => {
    setOpen(open => !open);
  };

  const headerStyles = useMemo(() => (open ? openHeaderStyles : closedHeaderStyles), [
    open,
    openHeaderStyles,
    closedHeaderStyles,
  ]);

  return (
    <Paper id={id} sx={{ marginBottom: '10px', width: '100%' }} {...boxStyles}>
      <Header width="100%" {...headerStyles}>
        <h3 onClick={toggle}>{title}</h3>
        <Options>
          {options}
          <div onClick={toggle}>
            <Icon name={open ? 'chevronUp' : 'chevronDown'} color="grey[600]" size="24" />
          </div>
        </Options>
      </Header>
      {open && <Divider sx={{ my: 1 }} />}
      <Content open={open} width="100%" {...contentStyles}>
        {children}
      </Content>
    </Paper>
  );
};

export default CollapsibleBox;
