import React from 'react';
import styled from '@emotion/styled';
import { formatCurrency } from 'common/utils/helpers';
import { ITEM_SALE_STATUS_LABELS } from 'common/utils/constants';
import { sizing, SizingProps, spacing, SpacingProps } from '@mui/system';
import EthAddressExplorer from './EthAddressExplorer';

const Wrapper = styled.div<any>`
  width: 100%;
  margin: 16px 0;

  h5 {
    font-size: inherit;
    font-weight: 500;
    margin: 0 0 4px;
  }

  ${spacing}
  ${sizing}
`;

const Clickable = styled.div`
  color: ${props => (props.color ? props.color : props.theme.palette.primary.contrastText)};
  cursor: pointer;
`;

interface AttributeProps {
  title?: string;
  label?: string;
  name?: string;
  value?: any;
  isBoolean?: boolean;
  isAddress?: boolean;
  showFullAddress?: boolean;
  networkId?: number;
  currency?: string;
  isSaleStatus?: boolean;
  component?: any;
  disabled?: boolean;
  type?: string;
  options?: any[];
  isClearable?: boolean;
  placeholder?: any;
  step?: any;
  onClick?: any;
}

const Attribute: React.FC<AttributeProps & SpacingProps & SizingProps> = ({
  title,
  label,
  name,
  value,
  isBoolean,
  isAddress,
  showFullAddress,
  networkId,
  currency,
  isSaleStatus,
  onClick,
  ...rest
}) => {
  let renderedValue = value;

  if (isBoolean) {
    renderedValue = value ? 'Yes' : 'No';
  }

  if (isAddress) {
    renderedValue = (
      <EthAddressExplorer address={value} networkId={networkId} full={showFullAddress} />
    );
  }

  if (currency) {
    renderedValue = formatCurrency(value, currency);
  }

  if (isSaleStatus) {
    renderedValue = ITEM_SALE_STATUS_LABELS[value] || value;
  }

  return (
    <Wrapper {...rest}>
      <h5>{title || label || name}</h5>
      {onClick ? (
        <Clickable onClick={onClick}>{renderedValue || '-'}</Clickable>
      ) : (
        <div>{renderedValue || '-'}</div>
      )}
    </Wrapper>
  );
};

export default Attribute;
