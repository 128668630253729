import React from 'react';
import styled from '@emotion/styled';
import { bool, number, string, object, func, shape, oneOfType, instanceOf } from 'prop-types';
import FormControl from '@mui/material/FormControl';
import MUICheckbox from '@mui/material/Checkbox';
import get from 'lodash/get';
import { Label, FieldHelper } from '../index';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    padding-top: 10px;
  }
`;

const CheckboxField = ({
  disabled,
  readOnly,
  label,
  tabIndex,
  inputRef,
  primary,
  secondary,
  field: { name, value, onChange },
  form,
  labelStyles = {},
  required = false,
}) => {
  const error = get(form.errors, name);
  const touched = get(form.touched, name);
  const isDirty = touched || form.submitCount > 0;
  const hasError = isDirty && Boolean(error);

  return (
    <FormControl margin="normal" fullWidth>
      <Wrapper>
        {label && (
          <Label htmlFor={name} {...labelStyles}>
            {required ? `${label} *` : label}
          </Label>
        )}
        <MUICheckbox
          id={name}
          color={primary ? 'primary' : secondary ? 'secondary' : 'default'}
          inputRef={inputRef}
          checked={Boolean(value)}
          tabIndex={tabIndex}
          readOnly={readOnly}
          disabled={disabled}
          onChange={onChange}
          inputProps={{
            name,
          }}
        />
      </Wrapper>
      {hasError && <FieldHelper error>{error}</FieldHelper>}
    </FormControl>
  );
};

CheckboxField.propTypes = {
  disabled: bool,
  readOnly: bool,
  label: string,
  tabIndex: number,
  inputRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  onChange: func,
  primary: bool,
  secondary: bool,
  field: object,
  form: object,
  labelStyles: object,
  required: bool,
};

export default CheckboxField;
