import styled from '@emotion/styled';

const GridItem = styled.div`
  width: calc(25% - 12px);
  margin-right: 16px;
  margin-bottom: 16px;
  box-shadow: ${props => `0px 4px 16px ${props.theme.palette.grey[200]}`};
  background-color: white;
  padding: 16px;

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    width: calc(50% - 8px);

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
`;

export default GridItem;
