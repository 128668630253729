import styled from '@emotion/styled';

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;

  @media (max-width: 680px) {
    display: inline-block;

    input,
    button {
      margin-bottom: 8px;
    }
  }
`;
