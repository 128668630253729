import styled from '@emotion/styled';
import { node } from 'prop-types';

const FieldHelper = styled.div<{ error?: boolean; warning?: boolean }>`
  color: ${props =>
    props.error
      ? props.theme.palette.error.main
      : props.warning
      ? props.theme.palette.warning.main
      : props.theme.palette.info.main};
  font-size: ${props => props.theme.typography.fontSizes.small}px;
  margin: 8px 0;
`;

FieldHelper.propTypes = {
  children: node,
};

export default FieldHelper;
