import React from 'react';
import { bool, object, func } from 'prop-types';
import { connect } from 'react-redux';
import { isSnackbarVisible, getSnackbarOptions, hideSnackbar } from 'common/store/global';
import { Snackbar } from './index';

const GlobalSnackbar = ({ visible, options, hideSnackbar }) => (
  <Snackbar visible={visible} onClose={hideSnackbar} {...options} />
);

GlobalSnackbar.propTypes = {
  visible: bool,
  options: object,
  hideSnackbar: func,
};

const mapStateToProps = state => ({
  visible: isSnackbarVisible(state),
  options: getSnackbarOptions(state),
});

const mapDispatchToProps = {
  hideSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSnackbar);
