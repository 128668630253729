import { bool, number, string, func } from 'prop-types';
import MUISnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Snackbar = ({ visible, severity, message, timeout, onClose }) => (
  <MUISnackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={visible}
    message={message}
    autoHideDuration={timeout || 5000}
    onClose={onClose}
  >
    <Alert
      sx={{ opacity: '0.95 !important', px: 5, py: '5px' }}
      variant="filled"
      severity={severity}
    >
      {message}
    </Alert>
  </MUISnackbar>
);

Snackbar.propTypes = {
  visible: bool,
  severity: string,
  message: string,
  timeout: number,
  onClose: func,
};

export default Snackbar;
