export const getStatusColor = (props: any, defaultColor?: string) =>
  props.success
    ? props.theme.palette.success.main
    : props.error
    ? props.theme.palette.error.main
    : props.warning
    ? props.theme.palette.warning.main
    : props.notice
    ? props.theme.palette.primary.main
    : defaultColor || props.theme.palette.grey[300];
