import * as React from 'react';
import styled from '@emotion/styled';
import {
  sizing,
  palette,
  spacing,
  typography,
  borders,
  grid,
  SizingProps,
  PaletteProps,
  SpacingProps,
  TypographyProps,
  BordersProps,
  GridProps,
} from '@mui/system';

interface BoxBaseProps {
  children?: any;
  clear?: boolean;
  border?: boolean;
  shadow?: boolean;
  fullWidth?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export type BoxProps = SizingProps &
  PaletteProps &
  SpacingProps &
  TypographyProps &
  BordersProps &
  GridProps &
  BoxBaseProps;

const Box: React.FC<BoxProps> = styled.div<BoxProps>`
  display: inline-block;
  position: relative;
  background-color: ${({ clear }) => (clear ? 'transparent' : 'white')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  border-radius: 4px;
  padding: 8px;
  border: ${props => (props.border ? `1px solid ${props.theme.palette.grey[200]}` : 'none')};
  box-shadow: ${props => (props.shadow ? `0px 4px 16px ${props.theme.palette.grey[200]}` : 'none')};
  ${sizing};
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
  ${grid};
  ${props =>
    props.onClick &&
    `
    :hover {
      cursor: pointer;
    }
  `}
  }
`;

export default Box;
