import add from 'img/icons/add.svg';
import arrowUp from 'img/icons/arrow-up.svg';
import arrowRight from 'img/icons/arrow-right.svg';
import arrowDown from 'img/icons/arrow-down.svg';
import arrowLeft from 'img/icons/arrow-left.svg';
import arrowLeftDown from 'img/icons/arrow-left-down.svg';
import arrowLeftUp from 'img/icons/arrow-left-up.svg';
import arrowRightDown from 'img/icons/arrow-right-down.svg';
import arrowRightUp from 'img/icons/arrow-right-up.svg';
import barcode from 'img/icons/barcode.svg';
import building from 'img/icons/building.svg';
import calendar from 'img/icons/calendar.svg';
import camera from 'img/icons/camera.svg';
import cameraPlus from 'img/icons/camera-plus.svg';
import cameraRotate from 'img/icons/camera-rotate.svg';
import card from 'img/icons/card.svg';
import check from 'img/icons/check.svg';
import chevronUp from 'img/icons/chevron-up.svg';
import chevronRight from 'img/icons/chevron-right.svg';
import chevronDown from 'img/icons/chevron-down.svg';
import chevronLeft from 'img/icons/chevron-left.svg';
import clipboard from 'img/icons/clipboard.svg';
import close from 'img/icons/close.svg';
import copy from 'img/icons/copy.svg';
import collection from 'img/icons/collection.svg';
import webhooks from 'img/icons/webhooks.svg';
import networks from 'img/icons/networks.svg';
import dashboard from 'img/icons/dashboard.svg';
import deleteIcon from 'img/icons/delete.svg';
import drag from 'img/icons/drag.svg';
import edit from 'img/icons/edit.svg';
import exit from 'img/icons/exit.svg';
import email from 'img/icons/email.svg';
import envelope from 'img/icons/envelope.svg';
import ethereum from 'img/icons/ethereum.svg';
import eye from 'img/icons/eye.svg';
import error from 'img/icons/error.svg';
import file from 'img/icons/file.svg';
import filter from 'img/icons/filter.svg';
import grid from 'img/icons/grid.svg';
import help from 'img/icons/help.svg';
import image from 'img/icons/image.svg';
import images from 'img/icons/images.svg';
import imagePlus from 'img/icons/image-plus.svg';
import info from 'img/icons/info.svg';
import leaf from 'img/icons/leaf.svg';
import list from 'img/icons/list.svg';
import lock from 'img/icons/lock.svg';
import logout from 'img/icons/logout.svg';
import menu from 'img/icons/menu.svg';
import more from 'img/icons/more.svg';
import moreHorizontal from 'img/icons/more-horizontal.svg';
import newTab from 'img/icons/new-tab.svg';
import plus from 'img/icons/plus.svg';
import qrcode from 'img/icons/qrcode.svg';
import remove from 'img/icons/remove.svg';
import requests from 'img/icons/requests.svg';
import save from 'img/icons/save.svg';
import search from 'img/icons/search.svg';
import settings from 'img/icons/settings.svg';
import share from 'img/icons/share.svg';
import shield from 'img/icons/shield.svg';
import statistics from 'img/icons/statistics.svg';
import star from 'img/icons/star.svg';
import tag from 'img/icons/tag.svg';
import time from 'img/icons/time.svg';
import transparency from 'img/icons/transparency.svg';
import tree from 'img/icons/tree.svg';
import truck from 'img/icons/truck.svg';
import triangleUp from 'img/icons/triangle-up.svg';
import triangleDown from 'img/icons/triangle-down.svg';
import upload from 'img/icons/upload.svg';
import user from 'img/icons/user.svg';
import warning from 'img/icons/warning.svg';
import zoom from 'img/icons/zoom.svg';

interface IIcons {
  [name: string]: any;
}
const icons: IIcons = {
  add,
  arrowUp,
  arrowRight,
  arrowDown,
  arrowLeft,
  arrowLeftDown,
  arrowLeftUp,
  arrowRightDown,
  arrowRightUp,
  barcode,
  building,
  calendar,
  camera,
  cameraPlus,
  cameraRotate,
  card,
  check,
  chevronUp,
  chevronRight,
  chevronDown,
  chevronLeft,
  clipboard,
  close,
  copy,
  webhooks,
  networks,
  dashboard,
  delete: deleteIcon,
  drag,
  edit,
  exit,
  email,
  envelope,
  error,
  ethereum,
  eye,
  file,
  filter,
  grid,
  help,
  image,
  images,
  imagePlus,
  info,
  leaf,
  list,
  lock,
  logout,
  menu,
  more,
  moreHorizontal,
  newTab,
  qrcode,
  plus,
  remove,
  collection,
  requests,
  save,
  search,
  settings,
  share,
  shield,
  statistics,
  star,
  tag,
  time,
  transparency,
  tree,
  triangleUp,
  triangleDown,
  truck,
  upload,
  user,
  warning,
  zoom,
};

export default icons;
