import { string, any } from 'prop-types';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0;
  color: ${props => props.theme.palette.grey[400]};
  padding: 16px;

  h4 {
    margin: 16px 0;
    font-size: 24px;
  }

  p {
    margin: 0;
  }
`;

const EmptyStateMessage = ({ title, message }) => (
  <Wrapper>
    {title && <h4>{title}</h4>}
    <p>{message}</p>
  </Wrapper>
);

EmptyStateMessage.propTypes = {
  title: string,
  message: any,
};

export default EmptyStateMessage;
