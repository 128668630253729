export const getItem = key => localStorage.getItem(key);
export const setItem = (key, value) => localStorage.setItem(key, value);
export const removeItem = key => localStorage.removeItem(key);

export const loadState = (item = 'state') => {
  try {
    const serializedState = getItem(item);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
