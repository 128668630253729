import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { bool, number, string, object, any, array, func } from 'prop-types';
import MUIFormControl from '@mui/material/FormControl';
import get from 'lodash/get';
import { Label, Select, FieldHelper } from '../index';

const FormControl = styled(MUIFormControl)`
  && {
    z-index: unset;
  }
`;

const SelectField = ({
  disabled,
  label,
  placeholder = '',
  tabIndex,
  multiple,
  allowCustom,
  options,
  field: { name, onChange },
  form,
  required = false,
  noOptionsMessage,
  ariaLabel,
  isClearable,
}) => {
  const [selectValue, setSelectValue] = useState(null);
  const [selectOptions, setSelectOptions] = useState(options);

  const initialValue = get(form.initialValues, name);
  const currentFormValue = get(form.values, name);

  const getSelectValueFromForm = formValue => {
    let sValue = null;
    if (multiple) {
      const values = formValue ? formValue.split(',') : [];
      if (values) {
        sValue = options.filter(item => values.indexOf(item.value) !== -1);
      }
      setSelectOptions(options);
    } else {
      sValue = options.find(item => item.value === formValue);

      if (allowCustom && !sValue && formValue) {
        sValue = {
          value: formValue,
          label: formValue,
        };
        setSelectOptions(options => [...options, sValue]);
      } else {
        setSelectOptions(options);
      }
    }
    return sValue;
  };

  // initial value might change dynamically
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const sValue = getSelectValueFromForm(initialValue);
    setSelectValue(sValue);
  }, [initialValue, JSON.stringify(options)]);

  // form value might change programatically for single value fields
  useEffect(() => {
    if (multiple) {
      return;
    }
    const sValue = getSelectValueFromForm(currentFormValue);

    // avoid infinite loop by updating only when value actually changes
    if (sValue && sValue.value !== selectValue) {
      setSelectValue(sValue);
    }
  }, [currentFormValue, JSON.stringify(options)]);

  useEffect(() => {
    // parse object/array value into comma separated string for the field
    let fieldValue;
    if (multiple) {
      fieldValue =
        selectValue && selectValue.length > 0 ? selectValue.map(item => item.value).join(',') : '';
    } else {
      fieldValue = selectValue ? selectValue.value : '';
    }
    // avoid infinite loop by updating only when value actually changes
    if (currentFormValue !== fieldValue) {
      onChange({
        target: {
          name,
          value: fieldValue,
        },
      });
    }
  }, [selectValue]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const error = get(form.errors, name);
  const touched = get(form.touched, name);
  const isDirty = touched || form.submitCount > 0;
  const hasError = isDirty && Boolean(error);

  return (
    <FormControl margin="normal" fullWidth>
      {label && <Label htmlFor={name}>{required ? `${label} *` : label}</Label>}
      <Select
        id={name}
        name={name}
        label={label}
        tabIndex={tabIndex}
        placeholder={placeholder}
        onChange={setSelectValue}
        disabled={disabled}
        error={hasError}
        multiple={multiple}
        allowCustom={allowCustom}
        options={selectOptions}
        value={selectValue}
        noOptionsMessage={noOptionsMessage}
        ariaLabel={ariaLabel}
        isClearable={isClearable}
      />
      {hasError && <FieldHelper error>{error}</FieldHelper>}
    </FormControl>
  );
};

SelectField.propTypes = {
  tabIndex: number,
  label: any,
  placeholder: string,
  disabled: bool,
  multiple: bool,
  allowCustom: bool,
  options: array,
  field: object,
  form: object,
  required: bool,
  noOptionsMessage: func,
  ariaLabel: string,
  isClearable: bool,
};

export default SelectField;
