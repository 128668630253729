import { Theme } from '@mui/material/styles';
import HexagonStepLabelIcon from 'common/components/HexagonStepLabel';
import mapValues from 'lodash/mapValues';

const shadows: Array<string> = [];
for (let i = 0; i < 25; i += 1) {
  shadows.push(`0 1px 1px 1px rgba(0, 0, 0, 0.${i})`);
}

const baseFontSize = 14;

// TODO: get this setup and working and consistant throughout app
const legacyFontSizes = {
  h1: 32,
  h2: 24,
  h3: 20,
  h4: 16,
  base: baseFontSize,
  small: 12,
};

const fontSizes = {
  h1: 64,
  h2: 30,
  h5: 24,
  h6: 18,
  small: 12,
  base: baseFontSize,
};

export const latoFontName = 'Lato';

const typography = {
  fontFamily: 'Roboto, sans-serif',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: "url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap')",
  fontSize: baseFontSize,
  htmlFontSize: baseFontSize,
  // TODO: once this has all been made consistant, refactor uses of this to use other syntax
  fontSizes: legacyFontSizes,
  ...mapValues(fontSizes, size => ({ fontSize: size })),
  // ...figmaFontSizes,
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: 12,
  },
};

const gradients = {
  backdrop:
    'linear-gradient(328deg, rgba(252,253,254,1) 0%, rgba(245,249,253,1) 15%, rgba(238,244,251,1) 34%, rgba(223,234,247,1) 51%, rgba(238,244,251,1) 69%, rgba(245,249,253,1) 86%, rgba(252,253,254,1) 100%)',
  blue: 'linear-gradient(135deg, rgb(79 148 248 / 75%) 0%, #2C56DD 57%)',
  darkBlue: 'linear-gradient(135deg, rgb(79 148 248 / 75%) 0%, rgb(26 72 133 / 100%) 57%)',
  red: 'linear-gradient(150deg, rgba(196 79 79 / 57.5%) 0%, rgba(222 5 5 / 77.5%) 57%)',
  orange: 'linear-gradient(150deg, rgb(255 165 0 / 50%) 0%, rgb(227 132 36 / 77.5%) 57%)',
  green: 'linear-gradient(150deg, rgba(0 140 78 / 57.5%) 0%, rgba(22 175 126 / 100%) 57%);',
  cnftBlue: 'linear-gradient(43.42deg, #5295F7 12.33%, #0C6CF7 86.19%);',
  greyBlue:
    'linear-gradient(270deg, rgba(33, 55, 84, 0.87) -0.26%, rgba(56, 93, 143, 0.87) 99.74%)',
};

const customColors = {
  black: '#212121',
  red: '#de0505c6',
  orange: '#e38424c6',
  green: '#16af7e',
  gradient: gradients,
  stepper: {
    grey: '#B8B8B8',
  },
};

const mainColorTheme = {
  primary: {
    main: '#2C56DD',
    lighter: '#557af2',
    light: '#3e66e6',
    dark: '#2E4AA3',
    darker: '#1e3273',
    darkest: '#213754',
    contrastText: 'white',
  },
  secondary: {
    main: '#577181',
    lighter: '#E8F6F8',
    light: '#AECED9',
    dark: '#3F5A6E',
    darker: '#2B445C',
    contrastText: 'white',
  },
};

const palette = {
  ...mainColorTheme,
  ...customColors,
  gradient: { ...gradients },
};

const breakpointValues = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1536,
};

const customBreakpoints = {
  breakpoints: {
    keys: ['sm', 'md', 'lg'],
    up: key => `@media (min-width:${breakpointValues[key]}px)`,
  },
};

export interface CustomAppPalette {
  black: string;
  gradient: {
    cnftBlue: string;
    backdrop: string;
    blue: string;
    red: string;
    orange: string;
    green: string;
    darkBlue: string;
    greyBlue: string;
  };
  stepper: {
    grey: string;
  };
}

export interface AppTheme extends Theme {
  customPalette: CustomAppPalette;
}

export const theme: AppTheme = {
  props: {
    MuiInputLabel: {
      shrink: true,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          minWidth: '124px',
          textTransform: 'uppercase',
          '&:hover': {
            boxShadow: '0 0 3px 0 lightgrey',
          },
        },
        contained: {
          '&.Mui-disabled': {
            color: mainColorTheme.primary.contrastText,
            opacity: 0.5,
          },
        },
        containedPrimary: {
          background: customColors.gradient.cnftBlue,
          ' .MuiLoadingButton-loadingIndicator': {
            color: `${mainColorTheme.primary.contrastText}`,
          },
        },
        containedSecondary: {
          background: customColors.gradient.greyBlue,
          ' .MuiLoadingButton-loadingIndicator': {
            color: `${mainColorTheme.secondary.contrastText}`,
          },
        },
        outlinedSecondary: {
          background:
            'linear-gradient(135deg,  rgba(43, 68, 92, 0.25) 0%, rgba(255,255,255,100%) 7.5%, rgba(255,255,255,45%) 92.5%, rgba(43, 68, 92, 0.2) 100%)',
          border: '1px solid rgba(43, 68, 92, 0.5)',
          '&:hover': {
            border: '1px solid rgba(43, 68, 92, 0.5)',
          },
          color: customColors.black,
        },
        containedError: {
          background: customColors.gradient.red,
        },
        containedWarning: {
          background: customColors.gradient.orange,
        },
        containedSuccess: {
          background: customColors.gradient.green,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '100%',
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          padding: '5px 0',
          background: customColors.black,
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          background: 'white',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
        },

        paper: {
          padding: '0',
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '25px',
          border: '1px solid lightgrey',
          boxShadow: '0 0 0 0 black',
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          /*
            Selects have a dropdown that is both a Paper & Menu.
            If we don't use important, the Paper padding override wins.
           */
          padding: '0 !important',
        },

        item: {
          color: 'black',
        },
      },
    },

    MuiAlert: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: '5px',
          fontFamily: latoFontName,
          letterSpacing: '1px',
          boxShadow: `0 0 3px 0px black`,
        },
        outlinedInfo: {
          border: `1px solid ${mainColorTheme.primary.main}`,
          boxShadow: `0 0 3px ${mainColorTheme.primary.main} `,
          color: mainColorTheme.primary.main,
          '& .MuiSvgIcon-root': {
            color: mainColorTheme.primary.main,
          },
        },
        filledInfo: {
          background: customColors.gradient.blue,
        },
        outlinedError: {
          border: `1px solid ${customColors.red}`,
          boxShadow: `0 0 3px ${customColors.red} `,
          color: customColors.red,
          '& .MuiSvgIcon-root': {
            color: customColors.red,
          },
        },
        filledError: {
          background: customColors.gradient.red,
        },
        outlinedWarning: {
          border: `1px solid ${customColors.orange}`,
          boxShadow: `0 0 3px ${customColors.orange} `,
          color: '#e97500',
          '& .MuiSvgIcon-root': {
            color: '#e97500',
          },
        },
        filledWarning: {
          background: customColors.gradient.orange,
          color: customColors.black,
          fontWeight: 'bold',
        },
        outlinedSuccess: {
          border: `1px solid ${customColors.green}`,
          boxShadow: `0 0 3px ${customColors.green} `,
          color: customColors.green,
          '& .MuiSvgIcon-root': {
            color: customColors.green,
          },
        },
        filledSuccess: {
          background: customColors.gradient.green,
        },
      },
    },

    MuiStepper: {
      defaultProps: {
        alternativeLabel: true,
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        root: {
          'span.MuiStepLabel-alternativeLabel': {
            marginTop: 0,
          },
          'span.Mui-completed, span.Mui-active': {
            color: mainColorTheme.primary.lighter,
          },
        },
        label: {
          fontSize: 10,
        },
      },

      defaultProps: {
        StepIconComponent: HexagonStepLabelIcon,
      },
    },

    MuiStepConnector: {
      styleOverrides: {
        root: {
          background: customColors.stepper.grey,
          border: `3px solid ${customColors.stepper.grey}`,
          borderRadius: '15px',
          top: '18px',
          '&.Mui-completed, &.Mui-active': {
            background: customColors.gradient.blue,
            border: `3px solid ${mainColorTheme.primary.lighter}`,
            '& > .MuiStepConnector-line': {
              borderColor: mainColorTheme.primary.lighter,
            },
          },
        },
      },
    },

    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          display: 'flex',
          borderRadius: 0,
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.MuiToggleButton-primary': {
            background: 'transparent',
            color: 'rgba(0, 0, 0, 0.54)',
            flexGrow: 1,

            '&.Mui-selected': {
              background: customColors.gradient.blue,
              color: mainColorTheme.primary.contrastText,
              fontWeight: 'bold',
            },
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          ' input.MuiInputBase-input:focus': {
            boxShadow: `2px 2px 3px 0 lightgrey`,
            border: `0px !important`,
          },
          width: '100%',
        },
      },
    },
  },
  palette,
  // // TODO: refactor then remove this, these are in palette
  customPalette: {
    ...customColors,
  },
  typography,
  shadows,
  overrides: {
    MuiScopedCssBaseline: {
      root: {
        backgroundColor: 'transparent',
        // fontSize: `${baseFontSize}px`,
        boxSizing: 'border-box',
      },
      body: {
        lineHeight: '24px',
      },
    },
  },
  ...customBreakpoints,
  //  The rest of the custom global styles can be found in src/themes/PlatformThemeProvider.js
} as any;

export default theme;
