import React from 'react';
import styled from '@emotion/styled';
import { bool, number, string, object, func, any, oneOfType } from 'prop-types';
import FormControl from '@mui/material/FormControl';
import get from 'lodash/get';
import { Label, Icon, TextField, FieldHelper } from '../index';

const FieldIcon = styled(Icon)`
  position: absolute;
  z-index: 2;

  ${props =>
    props.small
      ? `
  top: ${props.label ? '21' : '5'}px;
  left: 6px;
`
      : `
  top: ${props.label ? '22' : '7'}px;
  left: 8px;
`}
`;

const InputField = props => {
  const {
    autoFocus,
    disabled,
    label,
    placeholder = '',
    type,
    pattern,
    min,
    max,
    step,
    tabIndex,
    innerRef = null,
    autoComplete,
    startAdornment,
    endAdornment,
    multiline,
    rowsMax,
    field: { name, value, onChange },
    form,
    required = false,
    icon,
    small,
    testId,
  } = props;

  const error = get(form.errors, name);
  const touched = get(form.touched, name);
  const isDirty = touched || form.submitCount > 0;
  const hasError = isDirty && Boolean(error);

  return (
    <FormControl margin="normal" fullWidth>
      {label && <Label htmlFor={name}>{required ? `${label} *` : label}</Label>}
      {icon && <FieldIcon color="primary.main" label={label} name={icon} size="24" small={small} />}
      <TextField
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        type={type}
        pattern={pattern}
        tabIndex={tabIndex}
        placeholder={placeholder}
        disabled={disabled}
        multiline={multiline}
        rowsmax={rowsMax}
        error={hasError}
        icon={icon}
        small={small}
        InputProps={{
          startAdornment,
          endAdornment,
          inputRef: innerRef,
          inputProps: {
            id: name,
            name,
            autoComplete,
            min,
            max,
            step,
            'data-testid': testId,
          },
        }}
      />
      {hasError && <FieldHelper error>{error}</FieldHelper>}
    </FormControl>
  );
};

InputField.propTypes = {
  type: string,
  min: oneOfType([number, string]),
  max: oneOfType([number, string]),
  pattern: string,
  step: string,
  tabIndex: oneOfType([number, string]),
  label: any,
  innerRef: func,
  placeholder: string,
  autoFocus: bool,
  disabled: bool,
  autoComplete: string,
  startAdornment: any,
  endAdornment: any,
  multiline: bool,
  rowsMax: number,
  field: object,
  form: object,
  required: bool,
  icon: string,
  small: bool,
  testId: string,
};

export default InputField;
