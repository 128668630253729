import { any, object } from 'prop-types';
import { ThemeProvider as StyledThemeProvider, Global, css } from '@emotion/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import _ from 'lodash';
import { theme as defaultTheme } from './default';

export const NestedThemeProvider = ({ customTheme, children }) => {
  // Override theme with properties passed by user
  const theme = createTheme(_.merge(defaultTheme, customTheme));

  return (
    <StyledThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledThemeProvider>
  );
};

NestedThemeProvider.propTypes = {
  customTheme: object,
  children: any,
};

const PlatformThemeProvider = ({ overrideTheme, children }) => {
  return (
    <StylesProvider>
      <NestedThemeProvider customTheme={overrideTheme}>
        <CssBaseline />
        <Global
          styles={css`
            h1,
            h2,
            h3,
            h4 {
              font-weight: 400;
            }

            h1 {
              font-size: ${defaultTheme.typography.h1.fontSize}px;
              line-height: 40px;
            }

            h2 {
              font-size: ${defaultTheme.typography.h2.fontSize}px;
              line-height: 32px;
            }

            a {
              color: ${defaultTheme.palette.primary.main};
              text-decoration: none;
              font-size: ${defaultTheme.typography.fontSize}px;
            }

            @media (max-width: 680px) {
              h1 {
                font-size: 24px;
                line-height: 32px;
              }
            }

            ::-webkit-scrollbar-track {
              background: transparent;
            }

            ::-webkit-scrollbar-thumb {
              background-color: ${defaultTheme.palette.secondary.main};
              border-radius: 20px;
              border: 16px solid transparent;
              background-clip: content-box;
            }

            ::-webkit-scrollbar-thumb:hover {
              background-color: ${defaultTheme.palette.primary.main};
            }
          `}
        />
        {children}
      </NestedThemeProvider>
    </StylesProvider>
  );
};

PlatformThemeProvider.propTypes = {
  overrideTheme: object,
  children: any,
};

export default PlatformThemeProvider;
