import styled from '@emotion/styled';

const Wrapper = styled.div`
  font-size: 12px;
  padding: 16px 0px;
  color: ${props => props.theme.palette.grey[400]};

  ${props =>
    props.bottom &&
    `
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
`}
`;

const Version = props => <Wrapper {...props}></Wrapper>;

export default Version;
