import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div<any>`
  position: absolute;
  top: 14px;
  right: 12px;
  transform: scale(0.6);
  z-index: 101;
`;

const Container = styled.div`
  position: relative;
  height: 28px;
  width: 36px;
  cursor: pointer;
`;

const Line = styled.div`
  position: absolute;
  display: block;
  height: 4px;
  width: 36px;
  border-radius: 2px;
  background-color: white;
`;

const LineTop = styled(Line)<any>`
  top: 0;
  transform-origin: 34px 2px;

  ${props =>
    props.open &&
    `
    animation: line-top-out 300ms linear normal;
    animation-fill-mode: forwards;
    background-color: white;
  `}

  ${props =>
    props.closed &&
    `
    animation: line-top-in 300ms linear normal;
    animation-fill-mode: forwards;
  `}
`;

const LineMiddle = styled(Line)<any>`
  top: 12px;
  transition: opacity 100ms linear;

  ${props =>
    props.open &&
    `
    opacity: 0;
  `}

  ${props =>
    props.closed &&
    `
    transition-delay: 100ms;
  `}
`;

const LineBottom = styled(Line)<any>`
  bottom: 0;
  transform-origin: 34px 2px;

  ${props =>
    props.open &&
    `
    animation: line-bot-out 300ms linear normal;
    animation-fill-mode: forwards;
    background-color: white;
  `}

  ${props =>
    props.closed &&
    `
    animation: line-bot-in 300ms linear normal;
    animation-fill-mode: forwards;
  `}
`;

interface BurgerIconProps {
  open: boolean;
  onToggle: () => void;
}

const BurgerIcon = ({ open, onToggle }: BurgerIconProps) => {
  // This looks redundant but trust me.
  const [closed, setClosed] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      return;
    }

    if (!open) {
      setClosed(true);
    } else {
      setClosed(false);
    }
  }, [open, firstTime]);

  return (
    <Wrapper open={open} onClick={onToggle}>
      <Container>
        <LineTop open={open} closed={closed} />
        <LineMiddle open={open} closed={closed} />
        <LineBottom open={open} closed={closed} />
      </Container>
    </Wrapper>
  );
};

export default BurgerIcon;
